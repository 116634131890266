import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import notification from '@/assets/notification/faliurnotification.mp3'

export default {
  methods: {
    showErrorMessage(text) {
      const audio = new Audio(notification)
      audio.play()
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text,
        },
      }, { timeout: 15000 })
    },
    convertAndNotifyError(err) {
      if (!(err.response && err.response.data)) return
      if (typeof err.response.data === 'string') {
        // eslint-disable-next-line no-param-reassign
        err.response.data = err.response.data.replace(/<\/?[^>]+(>|$)/g, '')
        // eslint-disable-next-line no-param-reassign
        err.response.data = JSON.parse(err.response.data.replace("Notice:  Unknown: file created in the system's temporary directory in Unknown on line 0", ''))
      }
      let errors
      // eslint-disable-next-line camelcase
      let error_out = ''
      if (err.response.status === 404) errors = ['404 Not Found']
      if (err.response.data.errors) {
        errors = err.response.data.errors
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const error in errors) {
          const element = errors[error]
          // eslint-disable-next-line camelcase
          error_out += ` ${element[0]} `
        }
      }

      if (!errors && err.response.data.message) {
        errors = errors || []
        if (typeof err.response.data.message === 'string') {
          errors.push(err.response.data.message)
        } else {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const key of Object.keys(err.response.data.message)) {
            errors.push(err.response.data.message[key].error)
          }
        }
      }

      // eslint-disable-next-line camelcase
      if (!error_out) {
        // eslint-disable-next-line camelcase
        error_out = errors.length !== 0 ? errors[0] : 'Sorry for the inconvenience! Please Try Again!!'
      }

      const audio = new Audio(notification)
      audio.play()

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Something went wrong',
          icon: 'XIcon',
          variant: 'danger',
          // eslint-disable-next-line no-nested-ternary,camelcase
          text: Array.isArray(errors) ? error_out : (`${err.response.data.message ? err.response.data.message : err.response.data.data ? err.response.data.data.message : ''}${error_out}`),
        },
      }, { timeout: 15000 })
    },
  },
}
